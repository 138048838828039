<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import moment from 'moment'
import { operatingCycle } from '@/utils/textFile'
export default {
  name: 'sessionManageDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        peopleLimit: 0,
        cycle: [],
        startTime: '00:00',
        endTime: '23:59',
      },
      typeDataZQ: operatingCycle,
    }
  },
  watch: {},
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/farmRound/detail?id=${id}`,
        })
        .then((result) => {
          this.detail.cycle = result.cycle ? result.cycle.split(',') : []
        })

    this.$forceUpdate()
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '场次名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                name: '场次开始时间',
                type: 'timePicker',
                cols: 12,
                key: 'startTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
              {
                name: '场次结束时间',
                type: 'timePicker',
                cols: 12,
                key: 'endTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'HH:mm',
                  allowClear: false,
                },
              },
            ],
          },
          {
            name: '场次周期',
            type: 'select',
            cols: 12,
            props: {
              mode: 'multiple',
            },
            sort: true,
            key: 'cycle',
            typeData: this.typeDataZQ,
            rules: [
              {
                required: true,
                type: 'array',
              },
            ],
          },
          {
            name: '人数限制',
            type: 'inputNumber',
            cols: 12,
            key: 'peopleLimit',
            rules: [
              {
                required: true,
              },
            ],
            props: {
              min: 0,
            },
          },
          {
            name: '备注',
            type: 'textArea',
            cols: 12,
            key: 'remark',
            minHeight: '100px',
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: (data) => {
            if (data.startTime && data.endTime) {
              if (!moment(`1970-01-01 ${data.startTime}:00`).isBefore(`1970-01-01 ${data.endTime}:00`)) {
                this.$message.warning('场次结束时间不能小于场次开始时间!')
                return false
              }
            }
            console.log('data', data)
            // return
            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: this.detail.id ? '/farmRound/update' : '/farmRound/save',
                params: {
                  ...data,
                  cycle: data.cycle.length > 0 ? data.cycle.join(',') : '',
                  // startTime: moment(data.startTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                  // endTime: moment(data.endTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                },
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.push('/commodityManagement/sessionManage')
                }, 500)
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/sessionManage')
          },
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1()]} />
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
